
.page-template {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    .operate {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
    }
    .table {
        flex: 1;
        overflow: hidden;
    }
    .paging-box {
        padding: 20px 0 30px;
        text-align: center;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
