
.table-list {
    .img-box {
        display: inline-block;
        box-sizing: border-box;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;
        .el-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #909399;
            background-color: #f5f7fa;
        }
    }
    .switch-box {
        display: flex;
        align-items: center;
    }
    .content {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

::v-deep .customTable {
    .el-table .cell div {
        display: inline-block;
    }
    .has-gutter .el-table__cell {
        color: #332d42 !important;
        background-color: #f0f3fc !important;
    }
    .cell {
        line-height: normal;
        white-space: nowrap;
    }
    .el-table__empty-block {
        display: none;
    }
}
